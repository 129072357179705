(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"), require("antd"), require("react-dom"), require("react-router-dom"), require("use-query-params"));
	else if(typeof define === 'function' && define.amd)
		define("@combateafraude/react", ["react", "classnames", "antd", "react-dom", "react-router-dom", "use-query-params"], factory);
	else if(typeof exports === 'object')
		exports["@combateafraude/react"] = factory(require("react"), require("classnames"), require("antd"), require("react-dom"), require("react-router-dom"), require("use-query-params"));
	else
		root["@combateafraude/react"] = factory(root["react"], root["classnames"], root["antd"], root["react-dom"], root["react-router-dom"], root["use-query-params"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__33__, __WEBPACK_EXTERNAL_MODULE__45__, __WEBPACK_EXTERNAL_MODULE__65__) {
return 