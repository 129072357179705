"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToTop = exports.scrollToElement = exports.scrollToBottom = void 0;
var scrollToBottom = function () {
    window.scrollTo(0, document.body.scrollHeight);
};
exports.scrollToBottom = scrollToBottom;
var scrollToElement = function (id) {
    var element = document.getElementById(id) || document.getElementById('main-content');
    if (!element)
        return;
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
};
exports.scrollToElement = scrollToElement;
var scrollToTop = function () {
    var _a;
    (_a = document.getElementById('main-content')) === null || _a === void 0 ? void 0 : _a.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};
exports.scrollToTop = scrollToTop;
