"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
require("./styles.less");
var Title = antd_1.Typography.Title, Text = antd_1.Typography.Text;
var PageTitle = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, _b = _a.align, align = _b === void 0 ? 'left' : _b;
    var subtitleContent = react_1.useMemo(function () {
        if (!subtitle)
            return react_1.default.Fragment;
        if (typeof subtitle === 'string') {
            return react_1.default.createElement(Text, { className: "subtitle" }, subtitle);
        }
        if (Array.isArray(subtitle)) {
            return subtitle.map(function (message) { return (react_1.default.createElement(Text, { className: "subtitle-break" }, message)); });
        }
        return react_1.default.Fragment;
    }, [subtitle]);
    return (react_1.default.createElement("div", { className: "custom-page-title align-" + align },
        react_1.default.createElement(Title, { className: "title" }, title),
        subtitleContent));
};
exports.default = PageTitle;
