// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-btn {\n  font-size: 16px;\n  font-weight: 700;\n}\n.custom-btn.ant-btn-primary:disabled {\n  color: #fff !important;\n}\n.custom-btn.custom-btn-rounded {\n  border-radius: 100px;\n}\n.custom-btn .custom-caf-icon {\n  vertical-align: -0.4rem;\n}\n.custom-btn .custom-caf-icon + span {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Button/Standard/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".custom-btn {\n  font-size: 16px;\n  font-weight: 700;\n}\n.custom-btn.ant-btn-primary:disabled {\n  color: #fff !important;\n}\n.custom-btn.custom-btn-rounded {\n  border-radius: 100px;\n}\n.custom-btn .custom-caf-icon {\n  vertical-align: -0.4rem;\n}\n.custom-btn .custom-caf-icon + span {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-btn": "custom-btn",
	"ant-btn-primary": "ant-btn-primary",
	"custom-btn-rounded": "custom-btn-rounded",
	"custom-caf-icon": "custom-caf-icon"
};
export default ___CSS_LOADER_EXPORT___;
