"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundNumberTwoPlaces = exports.numberToFormattedString = exports.toNumber = exports.toCurrency = void 0;
var toCurrency = function (value, prefix) {
    if (!value && value !== 0)
        return '';
    var formatted = parseFloat(String(value)).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
    });
    return prefix ? prefix + " " + formatted : formatted;
};
exports.toCurrency = toCurrency;
var toNumber = function (value) {
    if (!value && value !== 0)
        return null;
    var replacedValue = ("" + value)
        .replace(/R\$|[,|.]+/g, '')
        .replace(/([0-9]{2})$/g, '.$1');
    var formattedValue = parseFloat(replacedValue);
    if (("" + value).length === 1) {
        formattedValue /= 100;
    }
    return Number(formattedValue);
};
exports.toNumber = toNumber;
var numberToFormattedString = function (value) {
    return "R$ " + value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
exports.numberToFormattedString = numberToFormattedString;
var roundNumberTwoPlaces = function (num) {
    return Math.round((num + Number.EPSILON) * 100) / 100 || 0;
};
exports.roundNumberTwoPlaces = roundNumberTwoPlaces;
