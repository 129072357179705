// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.ant-switch:not(.ant-switch-small) .ant-switch-handle {\n  width: 16px;\n  height: 16px;\n  top: 3px;\n}\n.ant-switch:not(.ant-switch-small) .ant-switch-handle .ant-switch-loading-icon {\n  top: 1px;\n}\n.ant-switch:not(.ant-switch-small):not(.ant-switch-checked) .ant-switch-handle {\n  left: 3px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Form/Switch/styles.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAC7F;EACE,WAAW;EACX,YAAY;EACZ,QAAQ;AACV;AACA;EACE,QAAQ;AACV;AACA;EACE,SAAS;AACX","sourcesContent":["/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.ant-switch:not(.ant-switch-small) .ant-switch-handle {\n  width: 16px;\n  height: 16px;\n  top: 3px;\n}\n.ant-switch:not(.ant-switch-small) .ant-switch-handle .ant-switch-loading-icon {\n  top: 1px;\n}\n.ant-switch:not(.ant-switch-small):not(.ant-switch-checked) .ant-switch-handle {\n  left: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ant-switch": "ant-switch",
	"ant-switch-small": "ant-switch-small",
	"ant-switch-handle": "ant-switch-handle",
	"ant-switch-loading-icon": "ant-switch-loading-icon",
	"ant-switch-checked": "ant-switch-checked"
};
export default ___CSS_LOADER_EXPORT___;
