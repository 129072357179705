"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCpf = exports.validateCnpj = void 0;
/* eslint-disable no-plusplus */
var validateCnpj = function (cnpj) {
    var cnpjOnlyNumbers = cnpj.replace(/[^\d]+/g, '');
    if (cnpjOnlyNumbers === '')
        return false;
    if (cnpjOnlyNumbers.length !== 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (cnpjOnlyNumbers === '00000000000000' ||
        cnpjOnlyNumbers === '11111111111111' ||
        cnpjOnlyNumbers === '22222222222222' ||
        cnpjOnlyNumbers === '33333333333333' ||
        cnpjOnlyNumbers === '44444444444444' ||
        cnpjOnlyNumbers === '55555555555555' ||
        cnpjOnlyNumbers === '66666666666666' ||
        cnpjOnlyNumbers === '77777777777777' ||
        cnpjOnlyNumbers === '88888888888888' ||
        cnpjOnlyNumbers === '99999999999999')
        return false;
    // Valida DVs
    var tamanho = cnpjOnlyNumbers.length - 2;
    var numeros = cnpjOnlyNumbers.substring(0, tamanho);
    var digitos = cnpjOnlyNumbers.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0), 10))
        return false;
    tamanho += 1;
    numeros = cnpjOnlyNumbers.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1), 10))
        return false;
    return true;
};
exports.validateCnpj = validateCnpj;
var validateCpf = function (cpf) {
    var _a;
    var cpfOnlyNumbers = (_a = cpf === null || cpf === void 0 ? void 0 : cpf.toString()) === null || _a === void 0 ? void 0 : _a.replace(/\D/g, '');
    if ((cpfOnlyNumbers === null || cpfOnlyNumbers === void 0 ? void 0 : cpfOnlyNumbers.length) !== 11 ||
        !Array.from(cpfOnlyNumbers).filter(function (e) { return e !== cpfOnlyNumbers[0]; }).length) {
        return false;
    }
    var sum = 0;
    var rest;
    for (var i = 1; i <= 9; i += 1)
        sum += parseInt(cpfOnlyNumbers.substring(i - 1, i), 10) * (11 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11)
        rest = 0;
    if (rest !== parseInt(cpfOnlyNumbers.substring(9, 10), 10))
        return false;
    sum = 0;
    for (var i = 1; i <= 10; i += 1)
        sum += parseInt(cpfOnlyNumbers.substring(i - 1, i), 10) * (12 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11)
        rest = 0;
    if (rest !== parseInt(cpfOnlyNumbers.substring(10, 11), 10))
        return false;
    return true;
};
exports.validateCpf = validateCpf;
