"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var use_query_params_1 = require("use-query-params");
var ObjectParam = {
    encode: function (obj) {
        return use_query_params_1.encodeObject(obj, '->', '_');
    },
    decode: function (objStr) {
        return use_query_params_1.decodeObject(objStr, '->', '_');
    },
};
exports.default = ObjectParam;
