// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.custom-title-divider {\n  flex: 1;\n  width: 100%;\n  position: relative;\n}\n.custom-title-divider .title-divider-tag {\n  color: #fff;\n  font-weight: 500;\n  font-size: 14px;\n  padding: 0px 8px;\n  background: #bdbdbd;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Divider/Title/styles.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAC7F;EACE,OAAO;EACP,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":["/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.custom-title-divider {\n  flex: 1;\n  width: 100%;\n  position: relative;\n}\n.custom-title-divider .title-divider-tag {\n  color: #fff;\n  font-weight: 500;\n  font-size: 14px;\n  padding: 0px 8px;\n  background: #bdbdbd;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-title-divider": "custom-title-divider",
	"title-divider-tag": "title-divider-tag"
};
export default ___CSS_LOADER_EXPORT___;
