// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n#error-page {\n  width: 100%;\n  height: 100%;\n  padding: 0 12%;\n}\n#error-page .title {\n  text-align: center;\n  color: #2f2e41;\n  opacity: 0.75;\n  font-size: 34px;\n}\n#error-page .subtitle {\n  margin: 0;\n  color: #2f2e41;\n  opacity: 0.75;\n}\n#error-page img {\n  width: 68%;\n  max-height: 400px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ErrorPages/styles.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAC7F;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,eAAe;AACjB;AACA;EACE,SAAS;EACT,cAAc;EACd,aAAa;AACf;AACA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":["/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n#error-page {\n  width: 100%;\n  height: 100%;\n  padding: 0 12%;\n}\n#error-page .title {\n  text-align: center;\n  color: #2f2e41;\n  opacity: 0.75;\n  font-size: 34px;\n}\n#error-page .subtitle {\n  margin: 0;\n  color: #2f2e41;\n  opacity: 0.75;\n}\n#error-page img {\n  width: 68%;\n  max-height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-page": "error-page",
	"title": "title",
	"subtitle": "subtitle"
};
export default ___CSS_LOADER_EXPORT___;
