"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toMaskedCpf = exports.toMaskedCnpj = void 0;
var toMaskedCpf = function (value) {
    if (!value)
        return '';
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};
exports.toMaskedCpf = toMaskedCpf;
var toMaskedCnpj = function (value) {
    if (!value)
        return '';
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};
exports.toMaskedCnpj = toMaskedCnpj;
