// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.custom-page-title .title {\n  font-size: 30px;\n  margin-bottom: 0;\n  font-weight: 900;\n}\n.custom-page-title .subtitle {\n  color: #9b9b9b;\n}\n.custom-page-title .subtitle-break {\n  display: block;\n  color: #9b9b9b;\n}\n.custom-page-title.align-center {\n  text-align: center;\n}\n.custom-page-title.align-left {\n  text-align: left;\n}\n.custom-page-title.align-right {\n  text-align: right;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PageTitle/styles.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAC7F;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB","sourcesContent":["/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.custom-page-title .title {\n  font-size: 30px;\n  margin-bottom: 0;\n  font-weight: 900;\n}\n.custom-page-title .subtitle {\n  color: #9b9b9b;\n}\n.custom-page-title .subtitle-break {\n  display: block;\n  color: #9b9b9b;\n}\n.custom-page-title.align-center {\n  text-align: center;\n}\n.custom-page-title.align-left {\n  text-align: left;\n}\n.custom-page-title.align-right {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-page-title": "custom-page-title",
	"title": "title",
	"subtitle": "subtitle",
	"subtitle-break": "subtitle-break",
	"align-center": "align-center",
	"align-left": "align-left",
	"align-right": "align-right"
};
export default ___CSS_LOADER_EXPORT___;
