"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasRequiredPermissions = exports.mapUserPolicies = exports.createRegexFor = void 0;
var createRegexFor = function (policyOrResource) {
    return policyOrResource === '*' || policyOrResource === '*:*'
        ? new RegExp('.*')
        : new RegExp(policyOrResource);
};
exports.createRegexFor = createRegexFor;
var mapUserPolicies = function (policies) {
    return policies.reduce(function (acc, cur) {
        var _a;
        (_a = cur === null || cur === void 0 ? void 0 : cur.actions) === null || _a === void 0 ? void 0 : _a.forEach(function (action) {
            var _a;
            (_a = cur === null || cur === void 0 ? void 0 : cur.resources) === null || _a === void 0 ? void 0 : _a.forEach(function (resource) {
                acc.push({ effect: "" + cur.effect, action: action, resource: resource });
            });
        });
        return acc;
    }, []);
};
exports.mapUserPolicies = mapUserPolicies;
var hasRequiredPermissions = function (userPolicies, requiredActions, requiredResources, or) {
    if (or === void 0) { or = false; }
    var mappedUserPolicies = exports.mapUserPolicies(userPolicies);
    var requiredPermissions = [];
    requiredActions.forEach(function (action) {
        return requiredResources.length > 0
            ? requiredResources.forEach(function (resource) {
                return requiredPermissions.push({ action: action, resource: resource });
            })
            : requiredPermissions.push({ action: action, resource: '' });
    });
    var newRequiredPermissions = requiredPermissions.reduce(function (acc, cur) {
        var compatibleActions = mappedUserPolicies.filter(function (x) {
            return exports.createRegexFor((x === null || x === void 0 ? void 0 : x.action) || '').test((cur === null || cur === void 0 ? void 0 : cur.action) || '');
        });
        var compatibleDeniedResources = compatibleActions.filter(function (x) {
            return exports.createRegexFor((x === null || x === void 0 ? void 0 : x.resource) || '').test((cur === null || cur === void 0 ? void 0 : cur.resource) || '') &&
                x.effect === 'Deny';
        });
        var compatibleAllowedResources = compatibleActions.filter(function (x) {
            return exports.createRegexFor((x === null || x === void 0 ? void 0 : x.resource) || '').test((cur === null || cur === void 0 ? void 0 : cur.resource) || '') &&
                x.effect === 'Allow';
        });
        if (compatibleActions.length === 0) {
            acc.push(cur);
        }
        else if (compatibleDeniedResources.length > 0) {
            acc.push(cur);
        }
        else if (compatibleAllowedResources.length === 0) {
            acc.push(cur);
        }
        return acc;
    }, []);
    // In case you want an or operation
    if (or) {
        return newRequiredPermissions.length !== requiredPermissions.length;
    }
    // If there are still elements on the array, return permission denied
    return newRequiredPermissions.length === 0;
};
exports.hasRequiredPermissions = hasRequiredPermissions;
