"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useInterval = function (callback, delay) {
    var savedCallback = react_1.useRef();
    // Remember the latest callback
    react_1.useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval
    // eslint-disable-next-line consistent-return
    react_1.useEffect(function () {
        function tick() {
            if (savedCallback.current !== undefined) {
                savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current();
            }
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () {
                clearInterval(id_1);
            };
        }
    }, [callback, delay]);
};
exports.default = useInterval;
