"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var use_query_params_1 = require("use-query-params");
var components_1 = require("components");
var auth_1 = require("context/auth");
var utils_1 = require("./PermissionWrapper/utils");
var RouteWrapper = function (_a) {
    var _b = _a.isPrivate, isPrivate = _b === void 0 ? true : _b, Component = _a.component, or = _a.or, requiredPermissions = _a.requiredPermissions, rest = __rest(_a, ["isPrivate", "component", "or", "requiredPermissions"]);
    var _c = auth_1.useAuth(), user = _c.user, getLoggedUser = _c.getLoggedUser, isAuthenticated = _c.isAuthenticated;
    var authenticated = isAuthenticated && isAuthenticated();
    var query = use_query_params_1.useQueryParams({
        redirectUri: use_query_params_1.StringParam,
    })[0];
    react_1.useEffect(function () {
        if (user || !authenticated)
            return;
        if (!getLoggedUser)
            return;
        getLoggedUser();
    }, [authenticated, getLoggedUser, user]);
    return user || !authenticated ? (react_1.default.createElement(react_router_dom_1.Route, __assign({}, rest, { render: function (_a) {
            var location = _a.location;
            if (isPrivate !== authenticated) {
                if (isPrivate) {
                    var redirectUri = encodeURIComponent("" + location.pathname + location.search);
                    return (react_1.default.createElement(react_router_dom_1.Redirect, { to: {
                            pathname: '/signin',
                            search: "?redirectUri=" + redirectUri,
                            state: { from: location },
                        } }));
                }
                var pathname = '/dashboard';
                var search = '';
                if (query.redirectUri) {
                    var _b = decodeURIComponent(query.redirectUri).split('?'), path = _b[0], queryParams = _b[1];
                    pathname = path;
                    search = queryParams ? "?" + queryParams : '';
                }
                return (react_1.default.createElement(react_router_dom_1.Redirect, { to: { pathname: pathname, search: search, state: { from: location } } }));
            }
            if (requiredPermissions && requiredPermissions.length > 0) {
                var authorized = utils_1.hasRequiredPermissions(user, requiredPermissions, [], or);
                if (!authorized) {
                    return react_1.default.createElement(react_router_dom_1.Redirect, { to: { pathname: '/unauthorized' } });
                }
            }
            return react_1.default.createElement(Component, null);
        } }))) : (react_1.default.createElement(components_1.CAFLoader, null));
};
exports.default = RouteWrapper;
