"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var initialFilterData = function (_a) {
    var query = _a.query, queryParams = _a.queryParams;
    if (!queryParams)
        return {};
    var newFilterData = {};
    Object.keys(queryParams).forEach(function (key) {
        newFilterData[key] = query[key];
    });
    return newFilterData;
};
exports.default = initialFilterData;
