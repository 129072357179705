"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var components_1 = require("components");
require("./styles.less");
/**
 * Utilizado como placeholder quando não há dados para exibir ao usuário.
 */
var EmptyMessage = function (_a) {
    var _b = _a.description, description = _b === void 0 ? 'Vazio' : _b, _c = _a.type, type = _c === void 0 ? 'empty' : _c, _d = _a.visible, visible = _d === void 0 ? true : _d, _e = _a.withCard, withCard = _e === void 0 ? true : _e, children = _a.children, rest = __rest(_a, ["description", "type", "visible", "withCard", "children"]);
    var _f = react_1.useState({
        className: '',
        image: antd_1.Empty.PRESENTED_IMAGE_SIMPLE,
    }), settings = _f[0], setSettings = _f[1];
    react_1.useEffect(function () {
        var className = withCard ? 'with-card' : '';
        switch (type === null || type === void 0 ? void 0 : type.toLowerCase()) {
            case 'search':
                setSettings({
                    className: className + " empty-search",
                    image: react_1.default.createElement(components_1.Icon, { icon: "search", size: "lg" }),
                });
                break;
            case 'warning':
                setSettings({
                    className: className + " empty-warning",
                    image: react_1.default.createElement(components_1.Icon, { icon: "alert-triangle", size: "lg" }),
                });
                break;
            case 'error':
                setSettings({
                    className: className + " empty-error",
                    image: react_1.default.createElement(components_1.Icon, { icon: "circle_false", danger: true, size: "lg" }),
                });
                break;
            case 'image':
                setSettings({
                    className: className,
                    image: react_1.default.createElement(components_1.Icon, { icon: "image", size: "lg" }),
                });
                break;
            case 'compliance':
                setSettings({
                    className: className,
                    image: react_1.default.createElement(components_1.Icon, { icon: "admin", size: "lg" }),
                });
                break;
            case 'empty':
                setSettings({
                    className: className,
                    image: antd_1.Empty.PRESENTED_IMAGE_SIMPLE,
                });
                break;
            default:
                break;
        }
    }, [type, withCard]);
    return visible ? (react_1.default.createElement("div", { className: "custom-empty-message " + settings.className },
        react_1.default.createElement(antd_1.Empty, __assign({ description: description, image: settings.image }, rest), children))) : null;
};
exports.default = EmptyMessage;
