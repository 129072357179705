// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#hidden-paragraph-component {\n  display: flex;\n  align-items: center;\n}\n#hidden-paragraph-component .ant-typography {\n  display: inline;\n  margin: 0 !important;\n}\n#hidden-paragraph-component .ant-typography.overflow {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis !important;\n}\n#hidden-paragraph-component button {\n  font-weight: normal;\n  margin-right: 10px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/HiddenParagraph/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kCAAkC;AACpC;AACA;EACE,mBAAmB;EACnB,6BAA6B;AAC/B","sourcesContent":["#hidden-paragraph-component {\n  display: flex;\n  align-items: center;\n}\n#hidden-paragraph-component .ant-typography {\n  display: inline;\n  margin: 0 !important;\n}\n#hidden-paragraph-component .ant-typography.overflow {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis !important;\n}\n#hidden-paragraph-component button {\n  font-weight: normal;\n  margin-right: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden-paragraph-component": "hidden-paragraph-component",
	"ant-typography": "ant-typography",
	"overflow": "overflow"
};
export default ___CSS_LOADER_EXPORT___;
