// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-layout {\n  height: 100vh;\n}\n.app-layout #main-content {\n  overflow-x: auto;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/PrivateWrapper/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".app-layout {\n  height: 100vh;\n}\n.app-layout #main-content {\n  overflow-x: auto;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-layout": "app-layout",
	"main-content": "main-content"
};
export default ___CSS_LOADER_EXPORT___;
