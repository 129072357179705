"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var auth_1 = require("context/auth");
var utils_1 = require("./utils");
var PermissionWrapper = function (_a) {
    var children = _a.children, _b = _a.or, or = _b === void 0 ? false : _b, requiredPermissions = _a.requiredPermissions, _c = _a.requiredResources, requiredResources = _c === void 0 ? [] : _c, _d = _a.forbiddenContent, forbiddenContent = _d === void 0 ? null : _d;
    var user = auth_1.useAuth().user;
    var content = react_1.useMemo(function () {
        var userPermissions = (user === null || user === void 0 ? void 0 : user.policies) || [];
        var hasPermission = utils_1.hasRequiredPermissions(userPermissions, requiredPermissions, requiredResources || [], or);
        return hasPermission ? children : forbiddenContent;
    }, [
        children,
        forbiddenContent,
        or,
        requiredPermissions,
        requiredResources,
        user,
    ]);
    return content || null;
};
exports.default = react_1.memo(PermissionWrapper);
